<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <label>{{ $t('SHOW') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector v-select-custom"
              @input="pageSize"
            />
            <label>{{ $t('ENTRIES') }}</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center demo-inline-spacing">
              <!-- Add Categories -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-center
                variant="primary"
                class="d-flex"
                @click="categoriesView()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Categories') }}
              </b-button>
              <!-- Add Form -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="addData()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Form') }}
              </b-button>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center demo-inline-spacing">
              <b-form-input
                v-model="searchQuery"
                :placeholder="$t('Search') + '...'"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="respDataForm"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Categories -->
        <template #cell(categoriesFormSurveyId)="data">
          <span class="align-middle ml-50">{{
            data.item.categoriesFormSurveyId.title
          }}</span>
        </template>

        <!-- Column: Answer -->
        <template #cell(answer)="data">
          <b-button-group>
            <b-button
              v-for="(item, index) in data.item.answer"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="align-middle"
              :variant="
                data.item.answer[index].page != null
                  ? 'primary'
                  : 'outline-primary'
              "
              @click="addChild(data.item._id, index)"
            >{{ item.name }}</b-button>
          </b-button-group>
        </template>

        <!-- Column: Action -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <!-- Edit & Delete -->
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="EditForm(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('EDIT') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteForm(data.item._id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('DELETE') }}</span>
            </b-dropdown-item>
            -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >{{ currentPage * perPage - (perPage - 1) }} -
              {{
                queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
              }}
              {{ $t('of') }} {{ queriedItems }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="queriedItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="nextPage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <!-- Categories Modal -->
      <b-modal
        id="modal-center"
        centered
        :title="$t('addCategories')"
        :ok-title="$t('Reset')"
        :cancel-title="$t('Cancel')"
        cancel-variant="outline-secondary"
        @hidden="resetModal"
        @show="resetModal"
        @ok="handleOk"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="respDataCateg"
          responsive
          :fields="tableCategories"
          primary-key="_id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <!-- Edit & Delete -->
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="cateEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('EDIT') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="cateDelete(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col
                md="6"
                xl="10"
                class="mb-1"
              >
                <!-- Categories.Title -->
                <b-form-group
                  :label="$t('Title')"
                  label-for="title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="title"
                        v-model="title"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Title')"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="validationForm()"
                        >
                          {{ dataId !== null ? $t('Edit') : $t('Add') }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  VBModal,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BFormInput,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import storeModule from '../../../store/root/formSurvey'

const STORE_MODULE_NAME = 'formSurvey'

export default {
  components: {
    BModal,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BButtonGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 50,
      isSidebarActive: false,
      perPageOptions: [50, 100, 300, 500, 1000],
      sortBy: ref('_id'),
      title: '',
      required,
      dataId: null,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'categoriesFormSurveyId', label: this.$t('Categories'), sortable: false },
        { key: 'title', label: this.$t('Title'), sortable: false },
        { key: 'content', label: this.$t('Content'), sortable: false },
        { key: 'position', label: this.$t('Position'), sortable: false },
        { key: 'answer', label: this.$t('Answer'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    tableCategories() {
      return [
        { key: 'title', label: this.$t('Title'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    // formSurvey
    respDataForm() {
      return store.state.formSurvey.respDataForm.data
    },
    queriedItems() {
      return store.state.formSurvey.respDataForm.max || 50
    },
    // Categories
    respDataCateg() {
      return store.state.formSurvey.respDataCateg.data
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
    }
    // formSurvey
    store
      .dispatch(`${STORE_MODULE_NAME}/getFormSurvey`, obj)
      .then(result => {
        console.log('fetch Success : ', result)
        this.showToast(
          'success',
          'bottom-right',
          result.data.message,
          'CheckIcon',
          'เรียกข้อมูลสำเร็จ',
        )
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.showToast(
          'danger',
          'bottom-right',
          error.data.message,
          'XCircleIcon',
          'เรียกข้อมูลไม่สำเร็จ',
        )
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  methods: {
    addData() {
      this.$router.push({
        path: '/root/formsurvey/add',
      })
    },
    addChild(item, index) {
      this.$router.push({
        path: `/root/formsurvey/addchild/${item}/${index}`,
      })
    },
    EditForm(item) {
      this.$router.push({
        path: `/root/formsurvey/edit/${item._id}`,
      })
    },
    deleteForm(id) {
      store
        .dispatch(`${STORE_MODULE_NAME}/deleteFormSurvey`, id)
        .then(result => {
          console.log('Delete Success', result)
          this.showToast('success', 'top-right', 'Success', 'CheckIcon')
        })
        .catch(err => {
          console.log('Delete Failed', err)
          this.showToast('danger', 'top-right', 'Error ', 'XCircleIcon')
        })
    },
    cateEdit(item) {
      this.dataId = item._id
      this.title = item.title
    },
    cateDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch(`${STORE_MODULE_NAME}/deleteCategoriesFormSurvey`, id)
              .then(result => {
                this.show = false
                console.log('fetch Success : ', result)
                this.showToast(
                  'success',
                  'top-right',
                  result.data.message,
                  'CheckIcon',
                  'ลบข้อมูลสำเร็จ',
                )
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.showToast(
                  'danger',
                  'top-right',
                  error.data.message,
                  'XCircleIcon',
                  'ลบข้อมูลไม่สำเร็จ',
                )
              })
          } else {
            this.showToast(
              'warning',
              'top-right',
              'Delete cancel!',
              'AlertCircleIcon',
              'ยกเลิกการลบข้อมูล',
            )
          }
        })
    },
    categoriesView() {
      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      // Categories
      store
        .dispatch(`${STORE_MODULE_NAME}/getCategoriesFormSurvey`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
          this.showToast(
            'success',
            'bottom-right',
            result.data.message,
            'CheckIcon',
            'เรียกข้อมูลสำเร็จ',
          )
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.showToast(
            'danger',
            'bottom-right',
            error.data.message,
            'XCircleIcon',
            'เรียกข้อมูลไม่สำเร็จ',
          )
        })
      console.log(this.$refs)
    },
    initValues() {
      this.title = ''
      this.dataId = null
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            title: this.title,
          }
          if (this.dataId !== null) {
            store
              .dispatch(`${STORE_MODULE_NAME}/editCategoriesFormSurvey`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.showToast(
                  'success',
                  'bottom-right',
                  result.data.message,
                  'CheckIcon',
                  'แก้ไขข้อมูลสำเร็จ',
                )
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast(
                  'danger',
                  'bottom-right',
                  error.data.message,
                  'XCircleIcon',
                  'แก้ไขข้อมูลไม่สำเร็จ',
                )
              })
          } else {
            delete obj._id
            store
              .dispatch(`${STORE_MODULE_NAME}/addCategoriesFormSurvey`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.showToast(
                  'success',
                  'bottom-right',
                  result.data.message,
                  'CheckIcon',
                  'เพิ่ม Categories สำเร็จ',
                )
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast(
                  'danger',
                  'bottom-right',
                  error.data.message,
                  'XCircleIcon',
                  'เพิ่ม Categories ไม่สำเร็จ',
                )
              })
          }
        }
      })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      this.show = true
      store
        .dispatch('formSurvey/get', obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.showToast(
            'danger',
            'top-right',
            'FetchUsers Error ',
            'XCircleIcon',
            'เรียกข้อมูลไม่สำเร็จ',
          )
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.showToast(
            'danger',
            'top-right',
            'FetchUsers Error ',
            'XCircleIcon',
            'เรียกข้อมูลไม่สำเร็จ',
          )
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$refs.formData.reset()
      this.initValues()
    },
    resetModal() {
      this.initValues()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
